<template>
  <v-app>
    <div class="card card-custom">
      <div class="card-body">
            <div class="row">
              <div class="col-md-6 col-lg-6 col-sm-12">
                <h4><i class="fas fa-arrow-left pr-4 cursor-pointer" @click="back"></i>Result summary</h4>
              </div>
              <div class="col-md-6 col-lg-6 col-sm-12 text-right mb-3">
                <button @click="saveStudentResult()" class="btn btn-primary text--white ml-2">
                  <i class="fas fa-save"></i>Save
                </button>
              </div>
            </div>
            <div class="row mb-5" style="background-color:#e7f0f3">
              <div class="col-md-3 col-lg-3 col-sm-12">
                <b>Program</b> {{ (exam && exam.program) ? exam.program.title : null }} <br>
              </div>
              <div class="col-md-2 col-lg-3 col-sm-12">
                <b>Semester/year</b> {{ (exam && exam.grade) ? exam.grade.title : null }} <br>
              </div>
              <div class="col-md-3 col-lg-3 col-sm-12">
                <b>Exam</b> {{ exam ? exam.title : null }} <br>
              </div>
              <div class="col-md-3 col-lg-3 col-sm-12">
                <b>Exam Type</b> {{ exam ? exam.type.toUpperCase() : null }} <br>
              </div>
            </div>
            <div class="row" v-if="selected.length>0">
              <div class="col-4">
                <v-select
                    outlined
                    dense
                    label="Result Status"
                    v-model="status.result_status"
                    :items="['completed','published', 'pending']"
                >
                </v-select>
              </div>
              <div class="col-4">
                <v-select
                    outlined
                    dense
                    label="Exam Status"
                    v-model="status.exam_status"
                    :items="['completed','pending']"
                >
                </v-select>
              </div>
              <div class="col-4">
                <v-select
                    v-model="status.result"
                    outlined
                    dense
                    label="Result Status"
                    :items="['passed','failed']"
                >

                </v-select>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <div class="">
                  <table class="table table-stripe">
                    <thead>
                    <th style="width: 40px !important; white-space: pre-wrap;">
                      <v-checkbox @change="selectAll()" outlined dense></v-checkbox>
                    </th>
                    <th colspan="2">Student detail</th>
                    <th style="width: 120px !important; white-space: pre-wrap;">Total mark</th>
                    <th style="width: 90px !important; white-space: pre-wrap;">GPA</th>
                    <th style="width: 90px !important; white-space: pre-wrap;">Rank</th>
                    <th style="width: 90px !important; white-space: pre-wrap;">Division</th>
                    <th style="width: 120px !important; white-space: pre-wrap;">Attendance</th>
                    <th>Status</th>
                    <th></th>
                    <!--                    <th>Pass Mark</th>-->
                    <!--                    <th>Exam Status</th>-->
                    <!--                    <th>Result Status</th>-->
                    </thead>
                    <tbody>
                    <tr v-for="(student, index) of result_students" :key="index">
                      <td style="width: 40px !important; white-space: pre-wrap;">
                        <v-checkbox v-model="selected" :value="index" outlined
                                    dense></v-checkbox>
                      </td>
                      <td></td>
                      <td>
                        {{ student.name }} | <a href="#" @click.prevent="openResultSummaryDialog(student)">{{ student.crn_no }}</a>

                        <span class="text-secondary" v-if="student.symbol_no">
                        | <b> Symbol No:</b> {{ student.symbol_no != null ? student.symbol_no : 'N/A' }}
                        </span>

                          <div class="text-secondary">
                              <span class="font-weight-medium">Symbol No.:</span><span>{{student.exam_symbol_no}}</span>
                          </div>
                          <div class="text-secondary">
                              <span class="font-weight-medium"> External ID:</span><span>{{student.midas_id}}</span>
                          </div>
                      </td>
                      <td style="width: 120px !important; white-space: pre-wrap;padding:5px; vertical-align: middle; padding-top: 10px">
                        <v-text-field outlined dense
                                      v-model="student.total_obtained_mark"
                                      label="Marks"
                        ></v-text-field>
                      </td>
                      <td style="width: 90px !important; white-space: pre-wrap;padding:5px; vertical-align: middle; padding-top: 10px">
                        <v-text-field outlined dense
                                      v-model="student.total_gpa"
                                      label="GPA"
                        ></v-text-field>
                      </td>
                      <td style="width: 90px !important; white-space: pre-wrap;padding:5px; vertical-align: middle; padding-top: 10px">
                        <v-text-field outlined dense
                                      v-model="student.rank"
                                      label="Rank"
                        ></v-text-field>
                      </td>
                      <td style="width: 90px !important; white-space: pre-wrap;padding:5px; vertical-align: middle; padding-top: 10px">
                        <v-text-field outlined dense
                                      v-model="student.division"
                                      label="Div"></v-text-field>
                      </td>
                      <td style="width: 120px !important; white-space: pre-wrap;padding:5px; vertical-align: middle; padding-top: 10px">
                        <v-text-field outlined dense type="text"
                                      v-model="student.attendance"
                                      label="Attendance"></v-text-field>
                      </td>
                      <td>
                     <span class="badge mt-3" title="Exam status"
                           :class="student.exam_status ? student.exam_status =='completed'?'badge-success':'badge-warning' :'badge-warning'"
                     >{{ student.exam_status ? student.exam_status.toUpperCase() : 'Not taken' }}</span><br>
                        <span
                            class="badge mt-3" title="Result Status"
                            :class="student.result_status =='published'?'badge-success':student.result_status =='completed'?'badge-primary':'badge-warning'"
                            v-if="student.result_status ">{{ student.result_status.toUpperCase() }}</span>
                        <br>

                        <span class="badge mt-3" title="Result"
                              :class="student.result ? student.result =='passed'?'badge-primary':'badge-warning' :'badge-warning'"
                              v-if="student.result">{{ student.result.toUpperCase() }}</span>
                      </td>
                      <td class="text-center">
                        <template  v-if="student.exam_status!=null">
                          <b-dropdown
                              size="sm"
                              variant="link"
                              toggle-class="custom-v-dropdown btn btn-clean btn-hover-light-primary btn-sm btn-icon"
                              no-caret
                              right
                              no-flip
                          >
                            <template v-slot:button-content>
                              <i class="ki ki-bold-more-hor"></i>
                            </template>
                            <div class="navi navi-hover min-w-md-200px">
                              <b-dropdown-text tag="div" class="navi-item" v-if="student.exam_status!=null">
                                <a @click="openResultPdf(student.exam_id, student.student_id, 'certificate')" class="navi-link">
                                <span class="navi-icon">
                                  <i class="fas fa-file-pdf"></i>
                                </span>
                                  <span class="navi-text">Certificate</span>
                                </a>
                              </b-dropdown-text>
                              <b-dropdown-text tag="div" class="navi-item" v-if="student.exam_status!=null">
                                <a @click="openResultPdf(student.exam_id, student.student_id, 'print')" class="navi-link">
                                <span class="navi-icon">
                                  <i class="fas fa-print"></i>
                                </span>
                                  <span class="navi-text">Print preview</span>
                                </a>
                              </b-dropdown-text>
                            </div>
                          </b-dropdown>
                        </template>
                      </td>

                    </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
    </div>
    <v-dialog
        v-model="summary"
        width="900"
        title="Manage Result"
    >
      <v-card>
        <v-card-title>
          Result
        </v-card-title>
        <v-card-text>
          <div class="row">
            <div class="col-12">
              <!--              <pre>{{ exam_summary }}</pre>-->
              <table class="table">
                <thead>
                <tr>
                  <th>Full Name</th>
                  <td>{{ exam_summary.user_full_name }}</td>
                  <th>Result</th>
                  <td>{{ exam_summary.result }}</td>
                </tr>
                <tr>
                  <th>Rank</th>
                  <td>{{ exam_summary.rank }}</td>
                  <th>Division</th>
                  <td>{{ exam_summary.division }}</td>
                </tr>
                <tr>
                  <th>Total</th>
                  <td>{{ exam_summary.total_obtained_mark }}</td>
                  <th>GPA</th>
                  <td>{{ exam_summary.total_gpa }}</td>
                </tr>
                </thead>
              </table>
              <table class="table" v-if="exam_summary.courses && exam_summary.courses.length>0">
                <thead>
                <th>Subject</th>
                <th>Theory Marks</th>
                <th>Practical Marks</th>
                </thead>
                <tbody>
                <tr v-for="(course, index) of exam_summary.courses" :key="index">
                  <td>{{ course.exam_course_title ? course.exam_course_title.toUpperCase() : "-" }}</td>
                  <td>{{ course.theory_obtained_marks ? course.theory_obtained_marks : "-" }}</td>
                  <td>{{ course.practical_obtained_marks ? course.practical_obtained_marks : "-" }}</td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              color="blue darken-1"
              text
              @click="summary = false"
          >
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-app>
</template>
<script>
import AcademicClassService from "@/core/services/academic-class/AcademicClassService";
import ExamService from "@/core/services/exam/ExamService";
import StudentResultService from "@/core/services/user/student/result/StudentResultService";
import { API_URL} from "@/core/config";

const examService = new ExamService();
const academicClassService = new AcademicClassService();
const resultService = new StudentResultService();

export default {
  name: "academic-class",
  components: {},
  data() {
    return {
      results: [],
      selected: [],
      previously_selected: [],
      status: {
        result_status: null,
        exam_status: null,
        result: null
      },
      result_students: [],
      academic_classes: [],
      exams: [],
      exam: null,
      academic_id: null,
      page: null,
      total: null,
      perPage: null,
      summary: false,
      exam_summary: {},
    };
  },
  mounted() {
    // this.getAcademicClasses();
    this.getAssignedStudents();
    this.getExam();
  },
  computed: {
    examId() {
      return this.$route.params.examId;
    }
  },
  methods: {
    back() {
      this.$router.go(-1);
    },

    changeStatus() {
      this.selected.forEach(ele => {
        this.result_students[ele].exam_status = (this.status.exam_status) ? this.status.exam_status : this.result_students[ele].exam_status;
        this.result_students[ele].result_status = (this.status.result_status) ? this.status.result_status : this.result_students[ele].result_status;
        this.result_students[ele].result = (this.status.result) ? this.status.result : this.result_students[ele].result;
      });

      this.status = {
        result_status: null,
        exam_status: null,
        result: null
      }
    },

    selectAll() {
      if (this.selected.length > 0) {
        this.selected = [];
      } else {
        for (let index in this.result_students) {
          this.selected.push(parseInt(index));
        }
      }
    },

    getExam() {
      // this.$bus.emit("toggleLoader");
      examService
          .show(this.examId)
          .then(response => {
            this.exam = response.data.exam;
          })
          .catch(err => {
            // console.log(err);
          })
          .finally(() => {
            // this.$bus.emit("toggleLoader");
          });
    },
    getAssignedStudents() {
      // console.log('fetching')

      this.$bus.emit('toggleLoader');
      examService.getAssignedStudents(this.examId).then(response => {
        this.selectedStudents = response.data.students;
        this.result_students = this.selectedStudents.map(student => {
          return {
            student_id: student.student_id,
            crn_no: student.crn_no,
            name: student.name,
            result_added_by: null,
            exam_id: this.examId,
            exam_course_id: null,
            total_obtained_mark: student.total_obtained_mark,
            total_gpa: student.total_gpa,
            rank:  student.rank,
            division: student.division,
            result_status: student.result_status,
            exam_status: student.exam_status,
            result: student.result,
            attendance: student.attendance,
            exam_symbol_no: student.exam_symbol_no,
            midas_id: student.midas_id,
          };
        });
        this.$bus.emit('toggleLoader');
        // this.getResults();
      });
    },

    openDialog(exam) {
      this.$refs["exam"].showModal(exam);
    },
    assignCourse(exam) {
      this.$refs["assignCourse"].showModal(exam);
    },
    deleteAcademicYear(id) {
      this.$confirm({
        message: `Are you sure `,
        button: {
          no: "No",
          yes: "Yes"
        },
        callback: confirm => {
          if (confirm) {
            academicClassService.delete(id).then(response => {
              this.$snotify.success("Information deleted");
              this.getAssignedStudents();
            });
          }
        }
      });
    },
    // getResults() {
    //   resultService.getAllByExamId(this.examId).then(response => {
    //     this.results = response.data;
    //     if (this.results.length > 0) {
    //       for (let res in this.results) {
    //         this.result_students[res].total_marks = this.results[
    //             res
    //             ].total_marks;
    //         this.result_students[res].rank = this.results[res].rank;
    //         this.result_students[res].result_status = this.results[
    //             res
    //             ].result_status;
    //         this.result_students[res].exam_status = this.results[
    //             res
    //             ].exam_status;
    //         this.result_students[res].total_obtained_mark = this.results[
    //             res
    //             ].total_obtained_mark;
    //         this.result_students[res].division = this.results[res].division;
    //         this.result_students[res].total_gpa = this.results[res].total_gpa;
    //         this.result_students[res].result = this.results[res].result;
    //         this.result_students[res].result_id = this.results[res].id;
    //       }
    //     }
    //   });
    // },
    saveStudentResult() {

      this.$confirm({
        message: `Are you sure you want to delete this item?`,
        button: {
          no: "No",
          yes: "Yes"
        },
        callback: confirm => {
          if (confirm) {
            this.$bus.emit('toggleLoader');
            this.changeStatus();
            this.is_loading = true;
            resultService.saveStudentResult(this.examId, this.result_students).then(response => {
              this.$snotify.success("Information updated");
              this.is_loading = false;
              this.getAssignedStudents();
            }).catch(error => {
              // console.log(error)
            }).finally(() => {
              this.is_loading = false;
              this.$bus.emit('toggleLoader');
            })
          }
        }
      });
    },
    openResultSummaryDialog(result) {
      resultService.show(result.student_id, result.result_id).then(response => {
        this.exam_summary = response.data.result
        this.summary = true
      })
    },
    openResultPdf(examId, studentId, type) {
      window.open(
          API_URL + "user/result/session/student/detail/" + examId + '/' + studentId+'/'+ type,
          "_blank"
      );
    }
  },
};
</script>
